import React, { useEffect } from 'react'
import Navbar from '../Landingpages/Navbar'
import Footer from '../Landingpages/Footer'

const PrivacyPolicy = () => {
    let privacyPolicy = [
        {
            title: '1. Information We Collect',
            content: `We collect different types of information to provide and improve our services, including:`,
            list: [
                {
                    title: ' 1.1 Personal Information',
                    content: `Name, email address, phone number, mailing address
                Business name, job title, and company details
                Payment details for transactions (processed securely through third-party payment providers)`
                },
                {
                    title: '1.2 Non-Personal Information',
                    content: ` Browser type, IP address, device information
                Website usage data, including cookies and analytics
                Marketing preferences and interactions with our services`
                },
                {
                    title: ' 1.3 Information from Third Parties',
                    content: `Social media interactions,
                Publicly available sources,
                Business partners and affiliates,`
                },
            ]
        },
        {
            title: "2. How We Use Your Information",
            content: `We use your information for the following purposes:`,
            list: [
                { content: `To provide, maintain, and improve our services` },
                { content: `To process transactions and send invoices` },
                { content: `To communicate with you regarding updates, promotions, and inquiries` },
                { content: `To enhance user experience and personalize content` },
                { content: `To ensure security and prevent fraud` },
                { content: `To comply with legal obligations` },

            ]
        },
        {
            title: '3. How We Share Your Information',
            content: `We do not sell, rent, or trade your personal information. However, we may share data in the following cases:`,
            list: [
                { title: 'Service Providers', content: ` With trusted third parties who assist in service delivery (e.g., payment processors, marketing platforms)` },
                { title: 'Legal Compliance', content: `When required by law, legal proceedings, or law enforcement requests` },
                { title: 'Business Transfers', content: `In case of mergers, acquisitions, or company restructuring` },

            ]
        },
        {
            title: ' 4. Data Protection & Security',
            content: ` We implement strong security measures to protect your personal data:`,
            list: [
                { title: 'Encryption & Secure Storage', content: `Data is stored using encryption and secure protocols` },
                { title: 'Access Control', content: `Limited access to authorized personnel only` },
                { title: 'Regular Audits', content: `Periodic security assessments to prevent vulnerabilities` },

            ]
        },
        {
            title: ' 5. Your Rights & Choices',
            content: ` You have the right to:`,
            list: [
                { content: ' Access, update, or delete your personal information' },
                { content: 'Opt out of marketing communications' },
                { content: ' Request data portability (where applicable)' },
                { content: ' To exercise these rights, contact us at info@meridatechminds.com.' },

            ]
        },
        {
            title: '6. Cookies & Tracking Technologies',
            content: `We use cookies and similar technologies to improve website functionality and user experience. 
You can manage cookie preferences through your browser settings.`
        },
        {
            title: '7. Third-Party Links',
            content: `Our website may contain links to third-party sites. We are not responsible for their privacy practices, so please review their policies before providing personal information.`
        },
        {
            title: '8. Changes to This Policy',
            content: `We may update this Privacy Policy periodically. Any changes will be posted on this page with an updated revision date.`
        },
        {
            title: ' 9. Contact Us',
            content: `If you have any questions or concerns about our Privacy Policy, contact us at:`
        }
    ]



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <main className='col-12 px-2 col-sm-11 mx-auto poppins my-3 ' >
                <h2 className='text-2xl fw-semibold ' >Privacy Policy</h2>
                <p className='my-3 text-md ' >
                    Merida Tech Minds we values the privacy of our customers, clients, and website visitors. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you interact with our services, including software development, digital marketing, brand building, skill development courses, and HR consulting.
                </p>
                {
                    privacyPolicy.map((obj) => (
                        <main className=' my-3 ' >
                            <h4 className=' text-xl fw-semibold ' > {obj.title} </h4>
                            <p className='my-2 ' > {obj.content} </p>
                            <ul className='my-0 list-outside pl-[2rem] ' >
                                {
                                    obj.list?.map((obj2) => (
                                        <li className='left-5 my-1 ' >
                                            <h5 className='text-lg fw-medium ' > {obj2.title} </h5>
                                            <p className='my-1 text-md ' > {obj2.content} </p>
                                        </li>
                                    ))
                                }
                            </ul>
                        </main>
                    ))
                }
                <p className='my-2 ' > Email : <a href="mailto:info@meridatechminds.com" className='text-decoration-none text-slate-700 '> info@meridatechminds.com </a> </p>
                <p className='my-2 ' >Phone : <a href="tel:+919876543210" className='text-decoration-none text-slate-700 ' >98765 43210 </a></p>
                <p className='my-2 ' > Address : <span> 20-2 ,Keshava Krupa Building 2nd Floor,<br /> 30th cross, 10th Main Rd,<br /> 4th Block, Jayanagar, Bengaluru,<br /> Karnataka 560011 </span> </p>
            </main>

            <Footer />
        </div>
    )
}

export default PrivacyPolicy
